import { BrowserRouter, Route, Routes } from "react-router-dom";

// PAGES
import EncryptBinding from "bindings/encrypt_binding";
import FirebaseBinding from "bindings/firebase_binding";
import HttpBinding from "bindings/http_binding";
import { ScrollToTop } from "components";
import Authentication, {
  AuthenticationBindings,
  AuthenticationMidleware,
} from "features/authentication";
import PurchasePage from "features/purchase";

import HttpSalesBinding from "bindings/http_sales_binding";
import Chatbot from "features/chatbot";
import CheckoutApp from "features/checkout_app";
import CPFCapture from "features/cpf_capture";
import EmailCheck from "features/email_check";
import Faq from "features/faq";
import FeatureToggleBindings from "features/feature_toogle/feature_toggle_bindings";
import ContinueAnotherDevice from "../features/qr_code";
import LadingPage from "../pages/landing_page/index";
import NotFound from "../pages/notFound";

export default function RouteApp() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <FirebaseBinding>
          <FeatureToggleBindings>
            <AuthenticationBindings>
              <AuthenticationMidleware>
                <EncryptBinding>
                  <HttpBinding>
                    <HttpSalesBinding>
                      <Routes>
                        <Route path="*" element={<NotFound />} />
                        <Route path="/" element={<LadingPage />} />
                        <Route
                          path="/purchase/:appState?"
                          element={<PurchasePage />}
                        />
                        <Route path="/signin" element={<Authentication />} />
                        <Route path="/chat" element={<Chatbot fullScreen />} />
                        <Route path="/checkout" element={<CheckoutApp />} />
                        <Route path="/faq" element={<Faq />} />
                        <Route
                          path="/transfer-device"
                          element={<ContinueAnotherDevice />}
                        />
                        <Route path="/cpf-capture" element={<CPFCapture />} />
                        <Route path="/email-check" element={<EmailCheck />} />
                      </Routes>
                    </HttpSalesBinding>
                  </HttpBinding>
                </EncryptBinding>
              </AuthenticationMidleware>
            </AuthenticationBindings>
          </FeatureToggleBindings>
        </FirebaseBinding>
      </ScrollToTop>
    </BrowserRouter>
  );
}
