import * as Sentry from "@sentry/react";
import { IHttpClient } from "core/protocols/http_client";
import FaqEntity from "features/faq/domain/entities/faq_entity";

export interface IGetFaqUsecase {
  execute(): Promise<FaqEntity[]>;
}

export class GetFaqUsecaseImpl implements IGetFaqUsecase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  execute(): Promise<FaqEntity[]> {
    return this.httpClient
      .get<{ total: number; data: FaqEntity[] }>(`/faqs`)
      .then((response) => {
        const page = response.data;
        return page.data;
      })
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            "feature.profile": "GetFaqUsecaseImpl",
            "api.endpoint": `/bff/v1/faq`,
          },
        });
        throw error;
      });
  }
}
