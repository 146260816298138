import { ProductModel } from "models";
import { CustomerEntity } from "./customer_entity";

class PurchaseFromAppEntity {
  imei: string;
  state: string;
  auth_uid: string;
  invoice_date: string;
  product: ProductModel;
  customer: CustomerEntity;
  auth_token: string;
  constructor(props: {
    imei: string;
    state: string;
    auth_uid: string;
    invoice_date: string;
    product: ProductModel;
    customer: CustomerEntity;
    auth_token: string;
  }) {
    this.customer = props.customer;
    this.imei = props.imei;
    this.state = props.state;
    this.auth_uid = props.auth_uid;
    this.invoice_date = props.invoice_date;
    this.product = props.product;
    this.auth_token = props.auth_token;
  }
}
export default PurchaseFromAppEntity;
