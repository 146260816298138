import CustomerEntity from "./customer_entity";
import PaymentEntity from "./payment_entity";
import ProductEntity from "./product_entity";

class PaymentInputEntity {
  transactionId: string;
  providerId: string;
  mobileNumber?: string;
  productId: number;
  score?: number;
  imei?: string;
  payment?: PaymentEntity;
  costumer?: CustomerEntity;

  constructor(args: {
    transactionId: string;
    providerId: string;
    productId: number;
    imei?: string;
    mobileNumber?: string;
    product?: ProductEntity;
    payment?: PaymentEntity;
    customer?: CustomerEntity;
  }) {
    this.transactionId = args.transactionId;
    this.providerId = args.providerId;
    this.mobileNumber = args.mobileNumber;
    this.productId = args.productId;
    this.imei = args.imei;
    this.payment = args.payment;
    this.costumer = args.customer;
  }

  toMap(): any {
    return {
      transactionId: this.transactionId,
      providerId: this.providerId,
      mobileNumber: this.mobileNumber,
      imei: this.imei,
      productId: this.productId,
      payment: this.payment?.toMap(),
    };
  }

  toMapWitnCustumer(): any {
    return {
      transactionId: this.transactionId,
      providerId: this.providerId,
      mobileNumber: this.mobileNumber,
      imei: this.imei,
      productId: this.productId,
      payment: this.payment?.toMap(),
      customer: this.costumer?.toMap(),
    };
  }
}

export default PaymentInputEntity;
