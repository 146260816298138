import { createContext, useContext } from "react";
import { IGetFaqUsecase } from "./domain/usecases/get_faq_usecase";

const GetFaqContext = createContext<IGetFaqUsecase | undefined>(undefined);

export function useGetFaqUsecase() {
  const context = useContext(GetFaqContext);

  if (context === undefined) {
    throw new Error("IGetFaqContext not found");
  }

  return context;
}

export default GetFaqContext;
