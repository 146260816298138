export class CustomerAddressEntity {
  city?: string;
  complement?: string;
  name?: string;
  neighborhood?: string;
  number?: string;
  zipcode?: string;
  uf?: string;

  constructor(args: {
    city?: string;
    complement?: string;
    name?: string;
    neighborhood?: string;
    number?: string;
    zipcode?: string;
    uf?: string;
  }) {
    this.city = args.city;
    this.complement = args.complement;
    this.name = args.name;
    this.neighborhood = args.neighborhood;
    this.number = args.number;
    this.zipcode = args.zipcode;
    this.uf = args.uf;
  }
}

export class MSISDN {
  msisdn?: string;
  validated?: boolean;
  validatedAt?: string;

  constructor(args: {
    msisdn?: string;
    validated?: boolean;
    validatedAt?: string;
  }) {
    this.msisdn = args.msisdn;
    this.validated = args.validated ?? false;
    this.validatedAt = args.validatedAt;
  }
}

export class Email {
  email?: string;
  validated?: boolean;

  constructor(args: { email?: string; validated?: boolean }) {
    this.email = args.email;
    this.validated = args.validated ?? false;
  }
}

export class CustomerEntity {
  birthday?: string;
  cpf?: string;
  phone?: string;
  address?: CustomerAddressEntity;
  msisdns?: MSISDN[];
  emails?: Email[];
  have_ticket?: boolean;
  fullname?: string;

  constructor(args: {
    birthday?: string;
    cpf?: string;
    phone?: string;
    address?: CustomerAddressEntity;
    msisdns?: MSISDN[];
    emails?: Email[];
    have_ticket?: boolean;
    fullname?: string;
  }) {
    this.birthday = args.birthday;
    this.cpf = args.cpf;
    this.phone = args.phone;
    this.address = args.address;
    this.msisdns = args.msisdns;
    this.have_ticket = args.have_ticket;
    this.fullname = args.fullname;
    this.emails = args.emails;
  }
}
