import * as Sentry from '@sentry/react';
import { IHttpClient } from "core/protocols/http_client";
import ProfileEntity from "../entities/profile_entity";

export type UpdateProfileInput = {
  authMethod: string,
  authUid: string,
  email: string,
  fullname?: string,
  profile: ProfileEntity,
}

type UpdateProfileOutput = {}

export interface IUpdateProfileUsecase {
  execute(args: UpdateProfileInput): Promise<UpdateProfileOutput>;
}

export default class UpdateProfileUsecaseImpl implements IUpdateProfileUsecase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  private createPayload(args: UpdateProfileInput) {
    const entity = args.profile;
    const [firstName, lastName] = args.fullname?.split(' ') || [];
    return {
      auth_method: args.authMethod,
      auth_uid: args.authUid,
      cpf: entity.cpf,
      email: args.email,
      phone: entity.phone.replace(/\D/g, ''),
      state: "logged",
      fullname: args.fullname,
      have_ticket: false,
      created_at: new Date().toISOString(),
      birthday: args.profile.birthday,
      customer: {
        address: {
          city: entity.address.localidade,
          complement: entity.address.complemento,
          neighborhood: entity.address.bairro,
          name: entity.address.logradouro,
          number: entity.address.numero !== '' ? entity.address.numero : '',
          state: entity.address.uf,
          zipcode: entity.address.cep
        },
        birthday: args.profile.birthday,
        cpf: entity.cpf,
        email: args.email,
        force_email: args.email,
        first_name: firstName || '',
        last_name: lastName || '',
        phone_number: entity.phone,
      }
    };
  }

  async execute(args: UpdateProfileInput): Promise<UpdateProfileOutput> {
    try {
      const payload = this.createPayload(args);
      const response = await this.httpClient.put<UpdateProfileOutput>('/bff/v1/user', payload);
      return response.data;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          'feature.profile': "UpdateProfileUsecaseImpl",
          'api.endpoint': `/bff/v1/user`,
        },
      });
      throw error;
    }
  }
}