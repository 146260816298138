import { useHttpSalesClient } from "bindings/http_sales_binding";
import { GetFaqUsecaseImpl } from "./domain/usecases/get_faq_usecase";
import GetFaqContext from "./get_faq_context";
import FaqPage from "./presenter/faq_page";

export default function FaqBinding() {
  const httpClient = useHttpSalesClient();

  return (
    <GetFaqContext.Provider value={new GetFaqUsecaseImpl(httpClient)}>
      <FaqPage />
    </GetFaqContext.Provider>
  );
}
